import React, { useState } from "react";
import EditIcon from "../../../../components/icons/edit";
import { PropertyType } from "../../../../redux/types";
import {
  fetchSingleProperty,
  updateProperty,
} from "../../../../redux/actions/property";
import { useDispatch } from "react-redux";
import DropdownTwo from "../../../../components/custom/drop-down-two";

interface PropertyPreferenceProps {
  activeListing: PropertyType;
}

const durationNo = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
];

const durationFrame = [
  { label: "Week", value: "week" },
  { label: "Month", value: "month" },
  { label: "Hour", value: "hour" },
  { label: "Minutes", value: "minutes" },
];

const PropertyPreference: React.FC<PropertyPreferenceProps> = ({
  activeListing,
}) => {
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(false);
  const [maxNoticePeriod, setMaxNoticePeriod] = useState(
    activeListing?.maximum_booking_notice || ""
  );
  const [minNoticePeriod, setMinNoticePeriod] = useState(
    activeListing?.maximum_booking_notice || ""
  );
  const [bookMethod, setBookMethod] = useState(
    activeListing?.booking_type || ""
  );

  const handleSaveClick = async () => {
    const res = await dispatch(
      // @ts-ignore
      updateProperty({
        id: activeListing?.id,
        maximum_booking_notice: maxNoticePeriod,
        minimum_booking_notice: minNoticePeriod,
        booking_type: bookMethod,
      })
    );

    if (res) {
      // @ts-ignore
      dispatch(fetchSingleProperty(activeListing?.id));
      setEditMode(false);
    }
  };

  const handleCancelClick = () => {
    setEditMode(false);
  };
  console.log("ssss", minNoticePeriod, maxNoticePeriod);
  return (
    <div className="h-fit w-full text-gray900 dark:text-gray100 border border-gray400 dark:border-gray800 rounded-3xl p-4 flex flex-col gap-2">
      <div className="flex flex-wrap md:gap-0 gap-3 items-center justify-between mb-5">
        <span className="font-bold">Property preference</span>
        {editMode ? (
          <div className="flex gap-2">
            <button
              onClick={handleCancelClick}
              className="bg-gray400 dark:bg-gray800 hover:bg-gray500 transition-all duration-200 ease-in-out h-10 flex items-center gap-2 rounded-full font-bold text-sm px-4"
            >
              Cancel
            </button>
            <button
              disabled={
                minNoticePeriod?.length < 2 ||
                maxNoticePeriod?.length < 2 ||
                bookMethod?.length < 2
              }
              onClick={handleSaveClick}
              className={` ${
                minNoticePeriod?.length < 2 ||
                maxNoticePeriod?.length < 2 ||
                bookMethod?.length < 2
                  ? "bg-gray400"
                  : "bg-primary500"
              }  hover:bg-primary600 transition-all duration-200 ease-in-out h-10 flex items-center gap-2 rounded-full font-bold text-sm px-4 text-white`}
            >
              Save
            </button>
          </div>
        ) : (
          <button
            onClick={() => setEditMode(true)}
            className="bg-gray400 dark:bg-gray800 hover:bg-gray500 transition-all duration-200 ease-in-out flex items-center gap-2 rounded-full font-bold text-sm h-9 px-3"
          >
            Edit
            <EditIcon />
          </button>
        )}
      </div>
      {editMode ? (
        <>
          <p className="text-xs font-semibold ">Minimum Notice Period</p>
          <div className="flex items-center gap-3 w-full">
            <DropdownTwo
              label="Duration"
              // @ts-ignore
              options={durationNo}
              // @ts-ignore
              onSelect={(e) => setMinNoticePeriod(e)}
              className="lg:w-1/3"
            />
            <DropdownTwo
              label="Durarion frame"
              // @ts-ignore
              options={durationFrame}
              // @ts-ignore
              onSelect={(e) => setMinNoticePeriod(minNoticePeriod + e)}
              className="lg:w-1/3"
            />
          </div>
          <p className="text-xs font-semibold">Maximum Notice Period</p>
          <div className="flex items-center gap-3 w-full">
            <DropdownTwo
              label="Duration"
              // @ts-ignore
              options={durationNo}
              // @ts-ignore
              onSelect={(e) => setMaxNoticePeriod(e)}
              className="lg:w-1/3"
            />
            <DropdownTwo
              label="Durarion frame"
              // @ts-ignore
              options={durationFrame}
              // @ts-ignore
              onSelect={(e) => setMaxNoticePeriod(maxNoticePeriod + e)}
              className="lg:w-1/3"
            />
          </div>
          <DropdownTwo
            label="Booking Method"
            // @ts-ignore
            options={[
              { label: "Instant booking", value: "instant" },
              { label: "Non Instant booking", value: "requires_approval" },
            ]}
            onSelect={(e) => setBookMethod(e)}
          />
        </>
      ) : (
        <>
          <p className="font-semibold text-gray800 dark:text-gray100">
            Minimum Notice Period
          </p>
          <p>{activeListing?.minimum_booking_notice}</p>
          <p className="font-semibold text-gray800 dark:text-gray100">
            Maximum Notice Period
          </p>
          <p>{activeListing?.maximum_booking_notice}</p>
          <p className="font-semibold text-gray800 dark:text-gray100">
            Booking method
          </p>
          <p>{activeListing?.booking_type}</p>
        </>
      )}
    </div>
  );
};

export default PropertyPreference;
