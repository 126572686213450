import React, { useEffect, useState } from "react";
import DataCard from "./latest-activity-data-card";
import Pagination from "../../../../components/custom/pagination";
import { useDispatch, useSelector } from "react-redux";
import { fetchHostBookings } from "../../../../redux/actions/property";
import { getHostBookings } from "../../../../redux/reducers/property-slice";
import { getIsLoading } from "../../../../redux/reducers/loader-slice";

interface DataFilterProps {}

// awaiting_approval
// upcoming
// current
// completed
// cancelled

const bookingFilter = [
  { name: "Pending", filter: "awaiting_approval" },
  { name: "Upcoming", filter: "upcoming" },
  { name: "History", filter: "completed" },
  { name: "Cancelled", filter: "cancelled" },
];

const DataFilter: React.FC<DataFilterProps> = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoading);
  const hostBookings = useSelector(getHostBookings);
  const [selectedFilter, setSelectedFilter] = useState("awaiting_approval");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  useEffect(() => {
    // @ts-ignore
    dispatch(fetchHostBookings(selectedFilter));

    // eslint-disable-next-line
  }, [selectedFilter]);

  const handleFilterChange = (filter: string) => {
    setSelectedFilter(filter);
    setCurrentPage(1);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const paginatedData = hostBookings.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const getStatusMessage = (status: string) => {
    switch (status) {
      case "awaiting_approval":
        return "No pending requests";
      case "upcoming":
        return "No upcoming booking";
      case "history":
        return "No History available";
      default:
        return "No data available for this filter";
    }
  };

  return (
    <div className="flex w-full mt-10 flex-col gap-5 items-start">
      <div className="flex items-center gap-4 text-sm md:text-lg font-semibold border-b border-gray400 dark:border-gray800 w-full pb-3">
        {bookingFilter.map((filter) => (
          <button
            key={filter.filter}
            onClick={() => handleFilterChange(filter.filter)}
            className={`transition-all duration-200 ease-in-out text-sm flex items-center gap-1 ${
              selectedFilter === filter.filter
                ? "text-gray1000 dark:text-gray100"
                : "text-gray600 dark:text-gray600"
            }`}
          >
            {filter.name}{" "}
            <div className={`w-4 h-4 rounded-full border ${ selectedFilter === filter.filter ? 'border-primary500 bg-primary100' : 'border-gray600'}  items-center justify-center flex`}>
              <span className={`text-xs ${ selectedFilter === filter.filter ? 'text-primary500' : 'text-gray600'}`}>0</span>
            </div>
          </button>
        ))}
      </div>
      {/* filtered data */}
      <div className="w-full flex flex-col gap-5 min-h-[30rem]">
        {isLoading ? (
          <div className="border border-gray400 rounded-md p-4 w-full">
            <div className="animate-pulse w-full">
              <div className="rounded-xl h-28 w-full bg-gray500"></div>
            </div>
          </div>
        ) : paginatedData.length > 0 ? (
          <div>
            {paginatedData.map((item, index) => (
              <DataCard key={index} data={item} filter={selectedFilter} />
            ))}
            <Pagination
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalItems={hostBookings.length}
              onPageChange={handlePageChange}
            />
          </div>
        ) : (
          <div className="w-full rounded-3xl h-[30rem] bg-gray300 flex items-center justify-center text-gray1000 font-semibold">
            {getStatusMessage(selectedFilter)}
          </div>
        )}
      </div>
    </div>
  );
};

export default DataFilter;
