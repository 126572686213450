import React, { useState } from "react";
// import Calendar from "../../../components/custom/calendar";
import HostDashboardLayout from "./components/host-dashboard-layout";
import StarPrimary from "../../../components/icons/star-primary";
import { currencyFormat } from "../../../utils/helper-functions";
import { TextInput } from "../../../components/custom/text-input";
import DeleteIcon from "../../../components/icons/delete";
import EditIcon from "../../../components/icons/edit";

interface PaymentProps {}
interface ActivityCardItemProps {
  title: string;
  stats: string | number;
  percent: string | number;
  action: () => void;
  actionTitle?: string;
  isOverallRating?: boolean;
  bgColor?: string;
  borderColor?: string;
}

const ActivityCardItem = ({
  title,
  stats,
  percent,
  action,
  actionTitle,
  isOverallRating,
  bgColor,
  borderColor,
}: ActivityCardItemProps) => (
  <div
    className={`border cursor-pointer flex flex-col gap-3 rounded-xl min-h-24 w-full ${bgColor} ${borderColor}`}
    onClick={action}
  >
    <div className="px-4 py-4 flex flex-col gap-2">
      <span className="text-base font-semibold text-gray1000 dark:text-gray200">
        {title}
      </span>
      <div className="flex items-center gap-2">
        <h1 className="text-4xl break-words font-bold">{stats}</h1>
        {isOverallRating && <StarPrimary />}
      </div>
      <div className="border-b-0 border-bg-gray600">
        <span className="text-gray800 dark:text-gray100 font-medium flex items-center gap-1">
          <span className="flex items-center gap-1">
            <span className="text-gray600 text-xs">- {percent}%</span>
            <span className="text-xs font-semibold"> since joining</span>
          </span>
        </span>
      </div>
    </div>
    <hr className={`bg-gray600 my-0 py-0 ${borderColor}`} />
    <div className="px-4 pb-4 flex gap-2 items-center">
      <span className="text-xs">{actionTitle}</span>
      {actionTitle && (
        <svg
          width="13"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.16663 6.66927H12.8333M12.8333 6.66927L6.99996 0.835938M12.8333 6.66927L6.99996 12.5026"
            stroke="#525252"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      )}
    </div>
  </div>
);

const PaymentAccount: React.FC<PaymentProps> = () => {
  const [active, setActive] = useState("all");

  return (
    <HostDashboardLayout
      crumbs={[{ name: "Payment and Account", path: `/payment-account` }]}
    >
      <div className="flex flex-col gap-10 md:px-7">
        <div className="flex md:flex-row flex-col gap-5 pt-2 font-display text-gray1000 mb-5">
          <div className="md:w-[68%] w-full h-fit md:border-r border-gray400 dark:border-gray800 md:pr-5 flex flex-col gap-5 pb-4">
            <div className="flex lg:flex-nowrap flex-wrap w-full items-center gap-5">
              <ActivityCardItem
                title="Book Balance"
                stats={currencyFormat(0)}
                percent={0}
                action={() => {}}
                actionTitle=" "
              />
              <ActivityCardItem
                title="Available Balance"
                stats={currencyFormat(0)}
                percent={0}
                action={() => {}}
                actionTitle="Request Payout"
              />
            </div>
            <div className="flex items-center justify-between">
              <p className="text-base font-semibold flex gap-2">
                Transactions{" "}
                <span className="text-xs bg-gray400 rounded-full p-1">100</span>
              </p>
              <button className="p-2 text-eadWhite bg-primary500 rounded-full text-xs">
                Request payout
              </button>
            </div>
            <div className="flex flex-wrap-reverse items-center justify-between gap-3">
              <div className="flex items-center border border-gray500 rounded-md">
                <p
                  onClick={() => setActive("all")}
                  className={`${
                    active === "all" && "bg-gray300"
                  } text-sm font-semibold border-r border-r-gray500 p-2 cursor-pointer`}
                >
                  All transactions
                </p>
                <p
                  onClick={() => setActive("inflow")}
                  className={`${
                    active === "inflow" && "bg-gray300"
                  } text-sm font-semibold border-r border-r-gray500 p-2 cursor-pointer`}
                >
                  In flow
                </p>
                <p
                  onClick={() => setActive("outflow")}
                  className={`${
                    active === "outflow" && "bg-gray300"
                  } text-sm font-semibold p-2 cursor-pointer`}
                >
                  Out flow
                </p>
              </div>
              <div className="flex items-center">
                <TextInput
                  placeholder="Search"
                  onChange={(e) => console.log(e.target.value)}
                  className="lg:w-full"
                />
              </div>
            </div>
            <div className="w-full">
              <table className="w-full text-sm">
                <thead>
                  <tr className="text-xs font-semibold border-t border-gray400 text-gray700">
                    <th className="py-5">Type</th>
                    <th className="py-5">Payment method</th>
                    <th className="py-5">Amount</th>
                    <th className="py-5">Date</th>
                    <th className="py-5">Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="text-sm text-gray800 border-y last:border-b border-gray400 cursor-pointer">
                    <td className="py-5">Booking</td>
                    <td className="py-5">Card payment</td>
                    <td className="py-5">{currencyFormat(5000)}</td>
                    <td className="py-5">27-05-2024 12:03 PM</td>
                    <td className="py-5">
                      <p className="p-1 bg-gray300 text-green-700 rounded-full w-fit">
                        Completed
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="md:w-[32%] w-full flex flex-col gap-3 bg-gray100 p-3 border border-gray400 rounded-xl">
            <p className="text-lg font-semibold dark:text-gray200">
              Payment method
            </p>
            <div className="border border-gray400 rounded-lg p-2 mb-3 flex flex-col gap-3">
              <p className="text-base font-semibold flex items-center gap-3">
                Paypal
                <span className="text-xs p-1 bg-gray300 text-green-500 rounded-full">
                  active
                </span>
              </p>
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-xs">John Alaba</p>
                  <p className="text-xs">jaon@gmail.com</p>
                </div>
                <div className="flex items-center gap-3">
                  <DeleteIcon />
                  <EditIcon />
                </div>
              </div>
            </div>
            <div className="border border-gray400 rounded-lg p-2 mb-3 flex flex-col gap-3">
              <p className="text-base font-semibold flex items-center gap-3">
                Paypal
                <span className="text-xs p-1 bg-gray300 text-green-500 rounded-full">
                  active
                </span>
              </p>
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-xs">John Alaba</p>
                  <p className="text-xs">jaon@gmail.com</p>
                </div>
                <div className="flex items-center gap-3">
                  <DeleteIcon />
                  <EditIcon />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </HostDashboardLayout>
  );
};

export default PaymentAccount;
