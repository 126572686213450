import { BsChevronRight } from "react-icons/bs";
import { CiCalendar } from "react-icons/ci";
import { useSelector } from "react-redux";
import { getGuestBookings } from "../../../../redux/reducers/property-slice";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { getIsLoading } from "../../../../redux/reducers/loader-slice";
import { paths } from "../../../../routes/paths";
import { currencyFormat } from "../../../../utils/helper-functions";

const UpcomingBookings = () => {
  const upcompingBookings = useSelector(getGuestBookings);
  const isLoading = useSelector(getIsLoading);
  const navigate = useNavigate();

  return (
    <div>
      {upcompingBookings.length === 0 && (
        <div className="w-full bg-primary100 border border-gray400 rounded-2xl h-96 flex flex-col gap-2 p-5 text-center items-center justify-center text-gray800 dark:text-gray200">
          <CiCalendar className="text-5xl" />
          <span className="font-bold text-2xl tracking-tight">
            No upcoming bookings
          </span>
          <span className="w-3/4 text-sm">
            With guaranteed quality, chose from our carefully selected homes
          </span>
          <button
            onClick={() => navigate(`${paths.SEARCH_LIST}`)}
            className="bg-primary500 font-display text-sm font-bold text-eadWhite text-center h-9 flex items-center justify-center px-3 rounded-full mt-3"
          >
            Find a property
          </button>
        </div>
      )}
      <div className="flex flex-col gap-2 font-display">
        {isLoading && upcompingBookings.length === 0 ? (
          <div className="animate-pulse w-full">
            <div className="flex flex-col gap-2 w-full">
              <div className="rounded-xl h-20 w-full bg-gray500"></div>
              <div className="rounded-xl h-40 w-full bg-gray500"></div>
            </div>
          </div>
        ) : (
          upcompingBookings.map((booking) => (
            <div
              key={booking.id}
              className="flex md:flex-row flex-col md:items-start gap-5 bg-gray200 text-gray1000 dark:text-gray100 dark:bg-transparent border border-gray400 dark:border-gray800 p-3 rounded-2xl"
            >
              <img
                src={
                  booking.property?.property_media
                    ? booking.property.property_media[0]?.url
                    : ""
                }
                alt={booking.booking_reference}
                className="md:w-1/3 w-full h-auto rounded-xl bg-gray500"
              />
              <div className="flex flex-col gap-1.5 text-gray900 dark:text-gray200">
                <span className="font-bold text-lg">
                  {booking.property.name}
                </span>
                <div className="flex items-center gap-1 text-gray800 dark:text-gray200 font-semibold text-sm">
                  <span>
                    {" "}
                    {moment(booking?.check_in_date).format("D MMM")}{" "}
                    {booking?.property.check_in_time}
                  </span>
                  <BsChevronRight />
                  <span>
                    {moment(booking?.check_out_date).format("D MMM")}{" "}
                    {booking?.property.check_out_time}
                  </span>
                </div>
                <span className="font-bold text-sm">
                  {currencyFormat(booking?.total_amount_paid || 0)}
                </span>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default UpcomingBookings;
