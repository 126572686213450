import { useForm, FormProvider } from "react-hook-form";
import BreadcrumbsTwo from "../../host-dashboard/components/breadcrumb-two";
import { paths } from "../../../../routes/paths";
import Navbar from "../../../../components/custom/navbar";
import ShareIcon from "../../../../components/icons/share";
import Carousel from "../../../../components/custom/carousel";
import { useNavigate, useParams } from "react-router-dom";
import { FaCheck } from "react-icons/fa6";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { PiHouseSimpleBold } from "react-icons/pi";
import { FiUser } from "react-icons/fi";
import { CgCreditCard } from "react-icons/cg";
import Footer from "../../../../components/custom/footer";
import { TextAreaInput } from "../../../../components/custom/text-input";
import { useEffect, useMemo, useState } from "react";
import { BsChevronRight } from "react-icons/bs";
import ReceiptEdge from "../../../../components/icons/ReceiptEdge";
import { useBookingInfo } from "../../../../hooks/use-book-summary";
import useQuery from "../../../../hooks/use-query";
import { RWebShare } from "react-web-share";
import moment from "moment";
import { currencyFormat } from "../../../../utils/helper-functions";
import apiClient from "../../../../network/axios-client";
import { urls } from "../../../../network";

const Booking = () => {
  const { id } = useParams();
  const query = useQuery();
  // const [phone, setPhone] = useState("");
  const navigate = useNavigate();
  // const profile = useSelector(getCurrentUser);
  // const [recipientPhone, setRecipientPhone] = useState("");
  const [details, setDetails] = useState("");
  const [isCompleted, setIsCompleted] = useState(false);
  // const [isToggleActive, setIsToggleActive] = useState(false);
  // const paymentOptions = ["Credit Card", "Debit Card"];
  // const [paymentMethod, setPaymentMethod] = useState("");
  const methods = useForm();

  const [toc, setToc] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrormsg] = useState("");

  useEffect(() => {
    if (query.get("reference")) {
      const referenceCode = query.get("reference") as string;
      verifyPayment(referenceCode);
    }

    // eslint-disable-next-line
  }, [query]);

  const { isLoadingBookinginfo, singleBookingInfo } = useBookingInfo({
    bookingId: Number(id),
  });

  // const handleToggle = () => {
  //   setIsToggleActive(!isToggleActive);
  // };

  const handleComplete = () => {
    setIsCompleted(!isCompleted);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // const handleDropdownSelect = (selectedOption: SetStateAction<string>) => {
  //   setPaymentMethod(selectedOption);
  // };

  const initiatePayment = async () => {
    const payload = {
      booking_id: id,
      callback_url: `${process.env.REACT_APP_WEB_URL}${paths.BOOKING}/${id}`,
    };
    setErrormsg("");
    setIsLoading(true);
    try {
      const res = await apiClient.post(urls.BookingPay, payload);
      setIsLoading(false);
      if (res.data.hasError) {
        setErrormsg(res.data.errors.message);
      } else {
        setErrormsg("");
        const { paystackData } = res.data.data;

        // Open Paystack payment page in a new tab
        const paymentWindow = window.open(paystackData?.authorization_url);

        if (paymentWindow) {
          const interval = setInterval(async () => {
            if (paymentWindow.closed) {
              setIsLoading(true);
              const res = await apiClient.get(
                urls.PaymentVerify(paystackData?.reference)
              );
              setIsLoading(false);
              if (res.data.hasError) {
                setErrormsg(res.data.errors.message);
              } else {
                handleComplete();
              }
              // window.location.href = "/checkout-success";
              clearInterval(interval);
            }
          }, 1000);
        } else {
          console.error("Failed to open payment window.");
        }
      }
    } catch (error) {
      console.log("Error initializing payment", error);
      setErrormsg("Error initializing payment");
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const verifyPayment = async (id: string) => {
    try {
      setIsLoading(true);
      const res = await apiClient.get(urls.PaymentVerify(id));
      setIsLoading(false);
      if (res.data.hasError) {
        setErrormsg(res.data.errors.message);
      } else {
        handleComplete();
      }
    } catch (error) {}
  };

  const eadFee = useMemo(() => {
    return (
      Number(singleBookingInfo?.total_amount_paid) -
        Number(singleBookingInfo?.property_rate) *
          Number(singleBookingInfo?.number_of_nights) || 0
    );
  }, [singleBookingInfo]);

  return (
    <div className="bg-eadWhite min-h-screen relative pb-18 px-4 dark:bg-gray1000 font-display text-gray1000 dark:text-gray100">
      <div className="max-w-screen-2xl mx-auto">
        <Navbar />
      </div>
      {isCompleted ? (
        <div className="max-w-screen-lg mx-auto lg:my-10">
          <div className="border border-gray400 dark:border-gray800 rounded-2xl p-5 md:p-10 flex md:flex-row flex-col-reverse items-center gap-10 md:gap-3">
            <div className="md:w-[55%] flex flex-col gap-3 text-gray900 dark:text-gray100">
              <div className="flex md:flex-col flex-row-reverse md:items-start items-center justify-end md:w-auto w-full gap-3">
                {" "}
                <FaCheck className="md:text-6xl text-3xl text-green-600" />
                <span className="md:text-4xl text-2xl font-bold tracking-tighter">
                  Reservation Completed!
                </span>
              </div>
              <span className="">
                Your reservation has now been completed successfully, the host
                will confirm your booking, usually in 24hrs. A message and email
                containing your booking details will be sent to you.
              </span>
              <button
                onClick={() => {
                  navigate(
                    `${paths.GUEST}/booking-details/${singleBookingInfo?.id}`
                  );
                }}
                className="bg-primary500 w-fit font-display text-sm font-bold text-eadWhite text-center h-9 flex items-center justify-center px-3 rounded-full mt-4"
              >
                View booking details
              </button>
            </div>
            <div className="md:w-[45%] flex flex-col">
              <ReceiptEdge className="-mb-0.5 w-full" />
              <div className="w-full bg-gray300 dark:bg-gray900 p-5">
                <div className="flex md:flex-row flex-col-reverse items-center gap-2 md:gap-5">
                  <div className="flex flex-col items-start md:w-auto w-full gap-1">
                    <span className="text-gray900 dark:text-gray100 text-lg font-bold">
                      {singleBookingInfo?.property.name}
                    </span>
                    <span className="flex items-center gap-1 text-gray800 dark:text-gray100 text-sm">
                      <HiOutlineLocationMarker className="text-base" />
                      {singleBookingInfo?.property.address}
                    </span>
                  </div>
                  <img
                    src={
                      (singleBookingInfo?.property?.property_media &&
                        singleBookingInfo?.property?.property_media[0]?.url) ||
                      ""
                    }
                    alt=""
                    className="md:w-1/3 rounded-xl bg-gray400"
                  />
                </div>
                <hr className="border-gray400 my-3 dark:border-gray800" />
                <div className="flex items-center justify-between">
                  <div className="flex flex-col gap-2 text-sm">
                    Checkin From
                    {isLoadingBookinginfo ? (
                      <div className="animate-pulse">
                        <div className="h-5 w-20 bg-gray600 rounded"></div>
                      </div>
                    ) : (
                      <span className="font-bold text-2xl md:text-3xl text-gray900 dark:text-gray100">
                        {moment(singleBookingInfo?.check_in_date).format(
                          "D/MM/YY"
                        )}
                      </span>
                    )}
                    {moment(singleBookingInfo?.check_in_date).format("dddd")}{" "}
                    {singleBookingInfo?.property.check_in_time}
                  </div>
                  <BsChevronRight className="text-gray700 text-3xl" />
                  <div className="flex flex-col gap-2 text-sm">
                    Check out
                    {isLoadingBookinginfo ? (
                      <div className="animate-pulse">
                        <div className="h-5 w-20 bg-gray600 rounded"></div>
                      </div>
                    ) : (
                      <span className="font-bold text-2xl md:text-3xl text-gray900 dark:text-gray100">
                        {moment(singleBookingInfo?.check_out_date).format(
                          "D/MM/YY"
                        )}
                      </span>
                    )}
                    {moment(singleBookingInfo?.check_out_date).format("dddd")}{" "}
                    {singleBookingInfo?.property.check_out_time}
                  </div>
                </div>
                <hr className="border-gray400 my-3 dark:border-gray800" />
                <div className="flex justify-between items-center text-gray1000 dark:text-gray100">
                  {isLoadingBookinginfo ? (
                    <div className="animate-pulse">
                      <div className="h-5 w-20 bg-gray600 rounded"></div>
                    </div>
                  ) : (
                    <span className="text-sm">
                      {currencyFormat(singleBookingInfo?.property_rate || 0)} x
                      {singleBookingInfo?.number_of_nights} night
                    </span>
                  )}
                  {isLoadingBookinginfo ? (
                    <div className="animate-pulse">
                      <div className="h-5 w-20 bg-gray600 rounded"></div>
                    </div>
                  ) : (
                    <span className="text-lg font-medium">
                      {currencyFormat(singleBookingInfo?.property_rate || 0)}
                    </span>
                  )}
                </div>
                <div className="flex justify-between items-center text-gray1000 dark:text-gray100">
                  <span className="text-sm">EAD Booking Fee</span>
                  {isLoadingBookinginfo ? (
                    <div className="animate-pulse">
                      <div className="h-5 w-20 bg-gray600 rounded"></div>
                    </div>
                  ) : (
                    <span className="text-lg font-medium">
                      {currencyFormat(eadFee || 0)}
                    </span>
                  )}
                </div>
                {/* <div className="flex justify-between items-center text-gray1000 dark:text-gray100">
                  <span className="text-sm">VAT</span>
                  {isLoadingBookinginfo ? (
                    <div className="animate-pulse">
                      <div className="h-5 w-20 bg-gray600 rounded"></div>
                    </div>
                  ) : (
                    <span className="text-lg font-medium">
                      {currencyFormat(singleBookingInfo?.vat || 0)}
                    </span>
                  )}
                </div> */}
                <hr className="border-gray400 my-3 dark:border-gray800" />
                <div className="flex justify-between items-center text-gray1000 dark:text-gray100 font-bold">
                  <span className="text-sm">Total</span>
                  {isLoadingBookinginfo ? (
                    <div className="animate-pulse">
                      <div className="h-5 w-20 bg-gray600 rounded"></div>
                    </div>
                  ) : (
                    <span className="text-lg">
                      {currencyFormat(
                        singleBookingInfo?.total_amount_paid || 0
                      )}
                    </span>
                  )}
                </div>
              </div>
              <ReceiptEdge className="-mt-0.5 rotate-180 w-full" />
            </div>
          </div>
        </div>
      ) : (
        <FormProvider {...methods}>
          <div className="max-w-screen-lg mx-auto lg:my-10">
            <div className="flex justify-between items-center">
              <BreadcrumbsTwo name={"Secure booking"} path={paths.BOOKING} />

              <RWebShare
                data={{
                  text: singleBookingInfo?.property?.name,
                  url: singleBookingInfo?.property?.share_link,
                  title: singleBookingInfo?.property?.name,
                }}
                onClick={() => console.log("shared successfully!")}
              >
                <button className="bg-gray400 dark:bg-gray900 dark:hover:bg-gray800 hover:bg-gray500 transition-all duration-300 ease-in-out text-gray1000 dark:text-gray100 text-sm px-3 rounded-full w-fit flex font-semibold items-center justify-center gap-2 p-2">
                  Share
                  <ShareIcon className="dark:stroke-gray600" />
                </button>
              </RWebShare>
            </div>
            <hr className="border-gray400 my-6 hidden lg:block dark:border-gray800" />
            {errorMsg.length > 1 && (
              <p className="text-base text-primary500 m-2">{errorMsg}</p>
            )}
            <div className="flex md:flex-row flex-col gap-5">
              {/* left */}
              <div className="md:w-[65%] md:mt-0 mt-5 flex flex-col gap-5">
                {/* Confirm your phone number */}
                {/* <div className="p-3 flex gap-2.5 flex-col rounded-2xl border border-gray400  dark:border-gray800 w-full">
                  <span className="text-xl font-bold mb-1">
                    Confirm your phone number
                  </span>
                  <PhoneInputField
                    label="Phone number"
                    value={phone}
                    className="lg:w-full dark:text-gray1000"
                    onChange={(phone) => setPhone(phone)}
                    inputProps={{
                      name: "phone",
                      required: true,
                      autoFocus: true,
                    }}
                    // isValid={phone.length > 9}
                  />
                  <div className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      className="accent-primary500"
                      name=""
                      id=""
                    />
                    <p className="text-xs text-gray800 dark:text-gray100 font-medium">
                      Receive text alerts about this booking (free of charge).
                    </p>
                  </div>
                  <hr className="border-gray400 my-2 dark:border-gray800" />
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <div className="w-12 h-12 flex items-center justify-center rounded-full bg-primary100 p-2.5">
                        <GoGift className="w-full h-full object-cover text-primary500" />
                      </div>
                      <div className="flex flex-col justify-between font-medium text-sm">
                        <span>Book for someone else</span>
                        <span className="text-gray700">
                          If this booking is for someone else
                        </span>
                      </div>
                    </div>
                    <ToggleButton
                      isActive={isToggleActive}
                      onToggle={handleToggle}
                    />
                  </div>
                  <div
                    className={`flex flex-col gap-2.5 transition-all duration-300 ease-in-out ${
                      isToggleActive ? "scale-100" : "scale-0 h-0"
                    }`}
                  >
                    <PhoneInputField
                      label="Recipient phone number"
                      value={recipientPhone}
                      className="lg:w-full"
                      onChange={(phone) => setRecipientPhone(phone)}
                      inputProps={{
                        name: "phone",
                        required: true,
                        autoFocus: true,
                      }}
                      // isValid={phone.length > 9}
                    />
                    <div className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        className="accent-primary500"
                        name=""
                        id=""
                      />
                      <p className="text-xs text-gray800 dark:text-gray100 font-medium">
                        Receive text alerts about this booking (free of charge).
                      </p>
                    </div>{" "}
                  </div>
                </div> */}
                {/* How to pay */}
                {/* <div className="p-3 flex gap-2.5 flex-col rounded-2xl border border-gray400  dark:border-gray800 w-full">
                  <span className="text-xl font-bold mb-1">How to pay</span>
                  <div className="flex flex-col gap-1 text-sm">
                    <label htmlFor="">Payment mode</label>
                    <Dropdown
                      onSelect={handleDropdownSelect}
                      options={paymentOptions}
                      defaultValue="Credit or Debit Card"
                      icon={
                        <FiUser className="text-lg text-gray800 dark:text-gray100" />
                      }
                    />
                  </div>
                  <hr className="border-gray400 my-2 dark:border-gray800" />
                  <div className="flex items-center gap-3 w-full">
                    <div className="w-[70%]">
                      <InputField
                        className="lg:w-full text-base"
                        label="Name on card"
                        placeholder="Enter your full name"
                        type="text"
                        name="name"
                      />
                    </div>
                    <div className="w-[30%]">
                      {" "}
                      <InputField
                        className="lg:w-full text-base"
                        label="Expiry"
                        placeholder="06/2024"
                        type="number"
                        name="expiry"
                      />
                    </div>
                  </div>{" "}
                  <div className="flex items-center gap-3 w-full">
                    <div className="w-[70%]">
                      <InputField
                        className="lg:w-full text-base"
                        label="Card number"
                        placeholder="Enter your card number"
                        type="text"
                        name="card number"
                        icon={<img alt="masterCard" src={MasterCard} />}
                      />
                    </div>
                    <div className="w-[30%]">
                      {" "}
                      <InputField
                        className="lg:w-full text-base"
                        label="CVV"
                        placeholder="•••"
                        type="password"
                        name="cvv"
                      />
                    </div>
                  </div>
                </div> */}
                {/* Send message to host */}
                <div className="p-3 flex gap-2.5 flex-col rounded-2xl border border-gray400  dark:border-gray800 w-full">
                  <span className="text-xl font-bold mb-1">
                    Send message to host
                  </span>
                  <div className="flex flex-col gap-1 text-sm">
                    <TextAreaInput
                      placeholder="Enter description"
                      label="Your message (optional)"
                      onChange={(val: any) => setDetails(val.target.value)}
                      value={details}
                      className="dark:bg-transparent"
                    />
                  </div>
                </div>
                {/* Agreement */}
                {/* <div className="p-3 flex gap-2.5 flex-col rounded-2xl border border-gray400  dark:border-gray800 w-full">
                  <span className="text-xl font-bold mb-1">Agreement</span>
                  <span className="text-gray800 dark:text-gray100 text-sm">
                    Your agreement is with Eve After Dark{" "}
                    <p className="mt-2">
                      By selecting Confirm and pay, I agree to the Host's House
                      Rules, Ground rules for guests, and Eve After Dark Refund
                      Policy.
                    </p>
                  </span>
                </div> */}
                {/* Cancellation Policy */}
                <div className="p-3 flex gap-2.5 flex-col rounded-2xl border border-gray400  dark:border-gray800 w-full">
                  <span className="text-xl font-bold mb-1">
                    Cancellation Policy
                  </span>
                  <ul className="text-sm text-gray800 dark:text-gray100 list-disc px-5 flex flex-col gap-2">
                    <li>
                      This rate is non-refundable. If you change or cancel your
                      booking you will not get a refund or credit to use for a
                      future stay. This policy will apply regardless of
                      COVID-19, subject to any local consumer laws.
                    </li>{" "}
                    <li>
                      No refunds will be issued for late check-in or early
                      check-out.
                    </li>
                    <li>Stay extensions require a new reservation.</li>
                  </ul>
                </div>
                {/* Ground rules */}
                <div className="p-3 flex gap-2.5 flex-col rounded-2xl border border-gray400  dark:border-gray800 w-full">
                  <span className="text-xl font-bold mb-1">Ground Rules</span>
                  <ul className="text-sm text-gray800 dark:text-gray100 list-disc px-5 flex flex-col gap-2">
                    {singleBookingInfo?.property?.rules.map((item: any) => (
                      <li key={item}>{item}</li>
                    ))}
                  </ul>
                </div>
              </div>
              {/* right */}
              <div className="p-3 flex-grow-0 h-fit rounded-2xl border border-gray400  dark:border-gray800 md:w-[35%] flex flex-col gap-2 text-gray900 dark:text-gray100">
                <Carousel
                  slides={
                    singleBookingInfo?.property?.property_media?.map(
                      (item) => ({ url: item.url, title: item.url })
                    ) || []
                  }
                  classNameImg="rounded-2xl object-cover w-full h-[10rem]"
                />
                {isLoadingBookinginfo ? (
                  <div className="animate-pulse">
                    <div className="h-5 w-20 bg-gray600 rounded"></div>
                  </div>
                ) : (
                  <span className="text-lg font-bold">
                    {singleBookingInfo?.property.name}
                  </span>
                )}
                {/* <div className="flex items-center gap-1 text-sm">
                  <FaStar className="text-amber-500 text-base" />
                  <span className="text-gray800 dark:text-gray100 font-semibold">
                    4.50 - 4 reviews
                  </span>
                </div> */}
                <hr className="border-gray400 my-2 dark:border-gray800" />
                <div className="flex items-center gap-10 text-gray700 text-sm">
                  <span className="flex items-center gap-3">
                    From
                    {isLoadingBookinginfo ? (
                      <div className="animate-pulse">
                        <div className="h-5 w-20 bg-gray600 rounded"></div>
                      </div>
                    ) : (
                      <span className="font-bold">
                        {moment(singleBookingInfo?.check_in_date).format(
                          "D/MM/YYYY"
                        )}
                      </span>
                    )}
                  </span>
                  <span className="flex items-center gap-3">
                    To
                    {isLoadingBookinginfo ? (
                      <div className="animate-pulse">
                        <div className="h-5 w-20 bg-gray600 rounded"></div>
                      </div>
                    ) : (
                      <span className="font-bold">
                        {moment(singleBookingInfo?.check_out_date).format(
                          "D/MM/YYYY"
                        )}
                      </span>
                    )}
                  </span>
                </div>
                <div className="flex items-center gap-2 text-gray700">
                  <PiHouseSimpleBold className="text-lg" />
                  {isLoadingBookinginfo ? (
                    <div className="animate-pulse">
                      <div className="h-5 w-20 bg-gray600 rounded"></div>
                    </div>
                  ) : (
                    <span className="text-sm">
                      {singleBookingInfo?.number_of_nights}-night stay
                    </span>
                  )}
                </div>
                <div className="flex justify-between items-center">
                  <div className="flex items-center gap-2 text-gray700">
                    <FiUser className="text-lg" />
                    {isLoadingBookinginfo ? (
                      <div className="animate-pulse">
                        <div className="h-5 w-20 bg-gray600 rounded"></div>
                      </div>
                    ) : (
                      <span className="text-sm">
                        {singleBookingInfo?.number_of_adults} guests
                      </span>
                    )}
                  </div>
                  <button
                    // onClick={() => navigate(-1)}
                    className="font-semibold text-gray800 dark:text-gray100 text-sm"
                  >
                    Edit booking
                  </button>
                </div>
                <hr className="border-gray400 my-2 dark:border-gray800" />
                <div className="flex justify-between items-center text-gray1000 dark:text-gray100">
                  {isLoadingBookinginfo ? (
                    <div className="animate-pulse">
                      <div className="h-5 w-20 bg-gray600 rounded"></div>
                    </div>
                  ) : (
                    <span className="text-sm">
                      {currencyFormat(singleBookingInfo?.property_rate || 0)} x
                      {singleBookingInfo?.number_of_nights} night
                    </span>
                  )}
                  {isLoadingBookinginfo ? (
                    <div className="animate-pulse">
                      <div className="h-5 w-20 bg-gray600 rounded"></div>
                    </div>
                  ) : (
                    <span className="text-lg font-medium">
                      {currencyFormat(singleBookingInfo?.property_rate || 0)}
                    </span>
                  )}
                </div>
                <div className="flex justify-between items-center text-gray1000 dark:text-gray100">
                  <span className="text-sm">EAD Booking Fee</span>
                  {isLoadingBookinginfo ? (
                    <div className="animate-pulse">
                      <div className="h-5 w-20 bg-gray600 rounded"></div>
                    </div>
                  ) : (
                    <span className="text-lg font-medium">
                      {currencyFormat(eadFee || 0)}
                    </span>
                  )}
                </div>
                {/* <div className="flex justify-between items-center text-gray1000 dark:text-gray100">
                  <span className="text-sm">VAT</span>
                  {isLoadingBookinginfo ? (
                    <div className="animate-pulse">
                      <div className="h-5 w-20 bg-gray600 rounded"></div>
                    </div>
                  ) : (
                    <span className="text-lg font-medium">
                      {currencyFormat(singleBookingInfo?.vat || 0)}
                    </span>
                  )}
                </div> */}
                <hr className="border-gray400 my-2 dark:border-gray800" />
                <div className="flex justify-between items-center text-gray1000 dark:text-gray100 font-bold">
                  <span className="text-sm">Total</span>
                  {isLoadingBookinginfo ? (
                    <div className="animate-pulse">
                      <div className="h-5 w-20 bg-gray600 rounded"></div>
                    </div>
                  ) : (
                    <span className="text-lg">
                      {currencyFormat(
                        singleBookingInfo?.total_amount_paid || 0
                      )}
                    </span>
                  )}
                </div>
                <button
                  disabled={!toc || isLoading}
                  className={`${
                    toc ? "bg-primary500" : "bg-primary200"
                  } cursor-pointer text-nowrap hover:bg-primary400 transition-all duration-200 ease-in-out my-3 text-white rounded-full flex font-semibold items-center justify-center text-sm p-3 gap-2`}
                  onClick={initiatePayment}
                >
                  {isLoading && (
                    <div className="border-eadWhite h-4 w-4 animate-spin rounded-full border-2 border-t-primary500 mx-1" />
                  )}
                  <CgCreditCard className="text-lg" />
                  Confirm reservation and pay
                </button>
                <div className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    className="accent-primary500"
                    name=""
                    id=""
                    onChange={() => setToc((prev) => !prev)}
                    checked={toc}
                  />
                  <p className="text-xs text-gray800 dark:text-gray100  font-medium">
                    I agree to all the terms & conditions
                  </p>
                </div>
              </div>
            </div>
          </div>
        </FormProvider>
      )}
      <Footer />
    </div>
  );
};

export default Booking;
