import HostDashboardLayout from "./components/host-dashboard-layout";
import HostDashboardHeader from "./components/host-dashboard-header";
import { Outlet } from "react-router-dom";
import MakeBookingSection from "../../../components/custom/make-bookings-section";
import Modal from "../../../components/custom/modal";
import UpdateProfileForm from "../../../components/custom/update-profile-form";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../../../redux/reducers/user-slice";

const HostDashboardPage = () => {
  const [openProfile, setOpenProfile] = useState(false);
  const profile = useSelector(getCurrentUser);

  useEffect(() => {
    if (profile?.phone === null || profile?.phone === "") {
      setOpenProfile(true);
    } else {
      setOpenProfile(false);
    }
  }, [profile]);

  return (
    <HostDashboardLayout>
      <HostDashboardHeader />
      <div className="md:px-4">
        <Outlet />
      </div>
      <MakeBookingSection />
      <Modal open={openProfile} onClose={() => {}} className="lg:w-1/3">
        <UpdateProfileForm onSetModal={() => setOpenProfile(false)} />
      </Modal>
    </HostDashboardLayout>
  );
};

export default HostDashboardPage;
