import { useNavigate } from "react-router-dom";
import StarPrimary from "../../../../components/icons/star-primary";
import { currencyFormat } from "../../../../utils/helper-functions";

interface ActivityCardProps {}
interface ActivityCardItemProps {
  title: string;
  stats: string | number;
  percent: string | number;
  action: () => void;
  actionTitle: string;
  isOverallRating?: boolean;
  bgColor?: string;
  borderColor?: string;
}

const ActivityCardItem = ({
  title,
  stats,
  percent,
  action,
  actionTitle,
  isOverallRating,
  bgColor,
  borderColor,
}: ActivityCardItemProps) => (
  <div
    className={`border cursor-pointer flex flex-col gap-3 rounded-xl min-h-24 ${bgColor} ${borderColor}`}
    onClick={action}
  >
    <div className="px-4 py-4 flex flex-col gap-2">
      <span className="text-base font-semibold text-gray1000 dark:text-gray200">
        {title}
      </span>
      <div className="flex items-center gap-2">
        <h1 className="text-4xl break-words font-bold">{stats}</h1>
        {isOverallRating && <StarPrimary />}
      </div>
      <div className="border-b-0 border-bg-gray600">
        <span className="text-gray800 dark:text-gray100 font-medium flex items-center gap-1">
          <span className="flex items-center gap-1">
            <span className="text-gray600 text-xs">- {percent}%</span>
            <span className="text-xs font-semibold"> since joining</span>
          </span>
        </span>
      </div>
    </div>
    <hr className={`bg-gray600 my-0 py-0 ${borderColor}`} />
    <div className="px-4 pb-4 flex gap-2 items-center">
      <span className="text-xs ">{actionTitle}</span>
      <svg
        width="13"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.16663 6.66927H12.8333M12.8333 6.66927L6.99996 0.835938M12.8333 6.66927L6.99996 12.5026"
          stroke="#525252"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  </div>
);

const ActivityCard: React.FC<ActivityCardProps> = () => {
  const navigate = useNavigate()
  return (
    <div className="grid md:grid-cols-3 gap-5">
      <ActivityCardItem
        title="All Booking"
        stats={0}
        percent={0}
        action={() => {}}
        actionTitle="My listing"
      />
      <ActivityCardItem
        title="Total earnings"
        stats={currencyFormat(0)}
        percent={0}
        action={() => navigate(`/payment-info`)}
        actionTitle="Payment & Account"
      />
      <ActivityCardItem
        title="Overall rating"
        stats={0.0}
        percent={0}
        action={() => {}}
        actionTitle="View ratings"
        isOverallRating
        bgColor="bg-[#FFF4D1]"
        borderColor="border-[#F2C94C]"
      />
    </div>
  );
};

export default ActivityCard;
