import React from "react";
import { BsChevronRight } from "react-icons/bs";
import { useSelector } from "react-redux";
import { getGuestBookings } from "../../../../redux/reducers/property-slice";
import moment from "moment";
import { currencyFormat } from "../../../../utils/helper-functions";
import { useNavigate } from "react-router-dom";
import { paths } from "../../../../routes/paths";

const PastBookings: React.FC = () => {
  const navigate = useNavigate();
  const upcompingBookings = useSelector(getGuestBookings);
  console.log("pastbook", upcompingBookings);
  return (
    <div className="flex flex-col gap-2 font-display">
      {upcompingBookings
        // .filter((item) => moment(new Date()).isAfter(item.check_out_date))
        .map((booking) => (
          <div
            key={booking.id}
            className="flex md:flex-row flex-col md:items-start gap-5 bg-gray200 text-gray1000 dark:text-gray100 dark:bg-transparent border border-gray400 dark:border-gray800 p-3 rounded-2xl"
          >
            <img
              src={
                booking.property?.property_media
                  ? booking.property.property_media[0]?.url
                  : ""
              }
              alt={booking.booking_reference}
              className="md:w-1/3 w-full h-auto rounded-xl bg-gray500"
            />
            <div className="flex flex-col gap-1.5 text-gray900 dark:text-gray200 w-full">
              <span className="font-bold text-lg">{booking.property.name}</span>
              <div className="flex items-center gap-1 text-gray800 dark:text-gray200 font-semibold text-sm">
                <span>
                  {" "}
                  {moment(booking?.check_in_date).format("D MMM")}{" "}
                  {booking?.property.check_in_time}
                </span>
                <BsChevronRight />
                <span>
                  {moment(booking?.check_out_date).format("D MMM")}{" "}
                  {booking?.property.check_out_time}
                </span>
              </div>
              <span className="font-bold text-sm">
                {currencyFormat(booking?.total_amount_paid || 0)}
              </span>
              <div className="flex items-center justify-between w-full">
                <button
                  onClick={() =>
                    navigate(`${paths.VIEW_PROPERTY}/${booking.property.slug}`)
                  }
                  className="bg-gray400 cursor-pointer mt-2 w-fit text-nowrap hover:bg-gray500 transition-all duration-200 ease-in-out text-gray1000 rounded-full flex font-bold items-center justify-center text-sm p-2 px-3 gap-2"
                >
                  Rebook
                </button>
                <button
                  onClick={() =>
                    navigate(`${paths.VIEW_PROPERTY}/${booking.property.slug}`)
                  }
                  className="bg-gray400 cursor-pointer mt-2 w-fit text-nowrap hover:bg-gray500 transition-all duration-200 ease-in-out text-gray1000 rounded-full flex font-bold items-center justify-center text-sm p-2 px-3 gap-2"
                >
                  Leave a review
                </button>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default PastBookings;
