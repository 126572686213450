import Bedroom from "../icons/bedroom";
import Pills from "./pills";
import Bathroom from "../icons/bathroom";
import Location from "../icons/location";
import Avatar from "../icons/avatar";
import Emailicon from "../icons/email";
import Whatsapp from "../icons/whatsapp";
import Phoneicon from "../icons/phone";
// import HeartEmpty from "../icons/heart-empty";
import Award from "../icons/award";
import { Link } from "react-router-dom";
import { currencyFormat, truncate } from "../../utils/helper-functions";
import { PropertyType } from "../../redux/types";
import ToiletIcon from "../icons/toilet";
import { Highlight } from "react-instantsearch";
import { useDispatch, useSelector } from "react-redux";
import { getFavourites } from "../../redux/reducers/property-slice";
import HeartFull from "../icons/heart-full";
import HeartEmpty from "../icons/heart-empty";
import {
  addFavouriteProperty,
  removeFavouriteProperty,
} from "../../redux/actions/property";
// import Carousel from "./carousel";

type Props = {
  price: string;
  icon?: any;
  pic?: string;
  title?: string;
  description?: string;
  location?: string;
  hostname?: string;
  to?: string;
  item?: PropertyType;
  hit?: any;
};

const SearchListing = ({
  title,
  icon,
  pic,
  price,
  description,
  location,
  hostname,
  to,
  item,
  hit,
}: Props) => {
  const dispatch = useDispatch();
  const favouritesProperties = useSelector(getFavourites);
  const isSaved = favouritesProperties
    .map((item) => item?.id)
    .includes(hit?.id);
  return (
    <div className="lg:flex my-4 gap-2 items-center relative w-full flex-shrink-0">
      <div className="w-full lg:w-1/2 relative">
        <Link to={to || "#"} target="_blank">
          <img src={pic} className="h-52 w-full rounded-lg" alt="" />
        </Link>
        <div className="flex items-center justify-between lg:hidden 2xl:hidden">
          <div className="flex gap-1 rounded-full bg-primary100 items-center p-1 absolute top-2 left-2">
            <Award />
            <span className="text-xs font-display text-primary500">
              Rare find
            </span>
          </div>
          <button
            className="absolute top-2 right-2"
            onClick={() => {
              isSaved
                ? // @ts-ignore
                  dispatch(removeFavouriteProperty(hit?.id))
                : // @ts-ignore
                  dispatch(addFavouriteProperty({ property_id: hit?.id }));
            }}
          >
            {isSaved ? (
              <HeartFull className="h-8" />
            ) : (
              <HeartEmpty className="h-8" />
            )}
          </button>
        </div>
      </div>
      <div className="flex flex-col gap-1 w-full">
        <div className="lg:flex items-center justify-between hidden">
          <div className="flex gap-1 rounded-full bg-primary100 items-center p-1">
            <Award />
            <span className="text-xs font-display text-primary500">
              Rare find
            </span>
          </div>
          <button
            className="absolute top-2 right-2"
            onClick={() => {
              isSaved
                ? // @ts-ignore
                  dispatch(removeFavouriteProperty(hit?.id))
                : // @ts-ignore
                  dispatch(addFavouriteProperty({ property_id: hit?.id }));
            }}
          >
            {isSaved ? (
              <HeartFull className="h-8" />
            ) : (
              <HeartEmpty className="h-8" />
            )}
          </button>
        </div>
        <Link
          to={to || "#"}
          target="_blank"
          className="flex items-center gap-2 mt-2"
        >
          <p className="text-xl font-display font-bold dark:text-gray200">
            {currencyFormat(price || 0)}
          </p>
          <p className="text-sm font-display text-gray900 dark:text-gray200">
            per night
          </p>
        </Link>
        <Link
          to={to || "#"}
          target="_blank"
          className="font-display text-base font-bold text-gray900 dark:text-gray200"
        >
          <Highlight attribute="name" hit={hit} />
        </Link>
        <div className="flex flex-col gap-1">
          <Link to={to || "#"} target="_blank">
            <p className="text-sm text-gray800 font-display dark:text-gray200">
              {truncate(description || "", 100)}
            </p>
          </Link>
          <Link
            to={to || "#"}
            target="_blank"
            className="flex items-center gap-2 overflow-x-scroll no-scrollbar my-1"
          >
            <Pills
              title={`${item?.bedrooms}Bedroom`}
              icon={<Bedroom className="w-3" />}
            />
            <Pills
              title={`${item?.bathrooms}Bathoom`}
              icon={<Bathroom className="w-3" />}
            />
            <Pills
              title={`${item?.toilets}Toilet`}
              icon={<ToiletIcon className="w-3" />}
            />
            <Pills
              title={`${item?.guests}Guest`}
              icon={<Bedroom className="w-3" />}
            />
          </Link>
          <Link
            to={to || "#"}
            target="_blank"
            className="flex gap-2 items-center"
          >
            <Location />
            <p className="text-xs font-display text-gray800 dark:text-gray200">
              <Highlight attribute="address" hit={hit} />
            </p>
          </Link>
          <div className="lg:flex items-center justify-between hidden">
            <Link
              to={to || "#"}
              target="_blank"
              className="flex items-center gap-2 mt-1"
            >
              <Avatar className="h-6" />
              <p className="text-xs font-display text-gray-800 dark:text-gray200">
                Hosted by {hostname}
              </p>
            </Link>
            <div className="flex gap-2">
              <Link
                className="flex"
                to={`mailto:${item?.contact_email}?subject=Bookings&body=Hello%20i%20want%20to%20book%20this%20property%20${item?.share_link}`}
              >
                <Emailicon className="h-4" />
                <span className="text-xs font-display dark:text-gray200">
                  Email
                </span>
              </Link>
              <Link
                className="flex"
                to={`https://wa.me/08054062639?text=Hello i want to book this property ${item?.share_link}`}
                target="_blank"
              >
                <Whatsapp className="h-4" />
                <span className="text-xs font-display dark:text-gray200">
                  Whatsapp
                </span>
              </Link>
              <Link className="flex" to={`tel:${item?.contact_phone_number}`}>
                <Phoneicon className="h-4" />
                <span className="text-xs font-display dark:text-gray200">
                  Call
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchListing;
