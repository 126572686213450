import { useState } from "react";
import OnboardingLayout from "../../../components/custom/onboarding-layout";
import Signinimg from "../../../assets/images/signinimg.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { InputField } from "../../../components/custom/text-input";
import { paths } from "../../../routes/paths";
import { useDispatch, useSelector } from "react-redux";
import { getIsLoading } from "../../../redux/reducers/loader-slice";
import { signInUserEmail } from "../../../redux/actions/authuser";
import { email_validation } from "../../../utils/input-validation";
import GoogleSignin from "../../../components/custom/google-signin-custom";

const Signin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useLocation();
  const methods = useForm();
  const isLoading = useSelector(getIsLoading);
  const [toc, setToc] = useState(false);

  const onSubmit = methods.handleSubmit(async (data) => {
    //  @ts-ignore
    const res = await dispatch(signInUserEmail(data));
    if (res) {
      methods.reset();
      localStorage.setItem("USERTYPE", "GUEST");
      if (state.state?.shouldGoback) {
        navigate(-1);
      } else {
        navigate(paths.GUEST + "/bookings");
        // navigate(paths.HOST_DASHBOARD + "/published-listing");
      }
    }
  });

  return (
    <OnboardingLayout image={Signinimg}>
      <div className="flex flex-col items-center justify-center pt-8 gap-6">
        <p className="text-center text-2xl text-gray1000 dark:text-gray200 font-display font-bold lg:w-1/2 xl:w-1/2 2xl:w-1/2">
          Great to have you back!
        </p>
        <p className="text-base text-gray1000 font-display mb-6 dark:text-gray200">
          Sign in to your account.
        </p>
      </div>
      <div className="flex flex-col items-center justify-center mx-3">
        <FormProvider {...methods}>
          <form
            onSubmit={(e) => e.preventDefault()}
            noValidate
            autoComplete="off"
            className="w-full flex flex-col items-center justify-center"
          >
            <div className="flex flex-col gap-4 w-full items-center justify-center">
              <InputField
                label="Your email*"
                placeholder="Enter your email"
                type="email"
                name="email"
                validation={email_validation}
              />
              <InputField
                label="Password*"
                placeholder="Enter your password"
                type="password"
                name="password"
              />
            </div>
            <div className="flex justify-between my-6 w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2">
              <div className="flex items-center justify-center">
                <input
                  type="checkbox"
                  className="accent-primary500"
                  name="remember"
                  id="remember"
                  onChange={() => setToc((prev) => !prev)}
                  checked={toc}
                />
                <span className="mx-1 text-xs font-display font-semibold text-gray1000 dark:text-gray200">
                  Remember me
                </span>
              </div>
              <Link
                to={paths.FORGOT_PASSWORD}
                className="text-xs font-display font-semibold text-primary500"
              >
                Forgot password
              </Link>
            </div>
            <button
              onClick={onSubmit}
              disabled={isLoading}
              className={`${
                isLoading ? "bg-primary300" : "bg-primary500"
              } opacity flex justify-center cursor-pointer p-2 text-gray100 rounded-full text-center font-display font-bold text-xs shadow-md mb-4 w-full lg:w-1/2 xl:w-1/2 2xl:w-1/2 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-100`}
            >
              {isLoading ? (
                <div className="border-eadWhite h-4 w-4 animate-spin rounded-full border-2 border-t-primary500" />
              ) : (
                "Sign in"
              )}
            </button>
          </form>
        </FormProvider>
        <GoogleSignin />
        <Link
          to={paths.SIGNUP}
          className="text-xs text-gray1000 dark:text-gray200 text-center my-2"
        >
          Don’t have an account?{" "}
          <span className="text-xs text-primary500 mx-2">Sign up</span>
        </Link>
      </div>
    </OnboardingLayout>
  );
};

export default Signin;
