import Navbar from "../../../components/custom/navbar";
import SearchIcon from "../../../components/icons/search";
import ShortletsCard from "../../../components/custom/shortlet-card";
import PlacesCard from "../../../components/custom/places-card";
import "./styles.scss";
import "flickity/css/flickity.css";
import Thisday from "../../../assets/images/thisday.png";
import Hotels from "../../../assets/images/hotels.png";
import Bbc from "../../../assets/images/bbc.png";
import Eaddark from "../../../assets/images/eaddark.png";
import Tripads from "../../../assets/images/tripads.png";
import Funtopia from "../../../assets/images/funtopia.png";
import Shrine from "../../../assets/images/shrine.png";
import Lcc from "../../../assets/images/lcc.png";
import Beach from "../../../assets/images/goodbeach.png";
import Footer from "../../../components/custom/footer";
import MakeBookingSection from "../../../components/custom/make-bookings-section";
import { paths } from "../../../routes/paths";
import { useContext, useEffect, useRef, useState } from "react";
import Modal from "../../../components/custom/modal";
import SearchModal from "./components/search-modal";
import YoutubeEmbed from "../../../components/custom/youtube-embed";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllProperties } from "../../../redux/actions/property";
import { getProperties } from "../../../redux/reducers/property-slice";
import { Link, useNavigate } from "react-router-dom";
import { SignInLinkContext } from "../../../context/sign-in-link-context";
import Flickity from "react-flickity-component";
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from "react-icons/io";
import Book4 from "../../../assets/images/book4.png";
import { getIsLoading } from "../../../redux/reducers/loader-slice";
import Loader from "../../loader-page";

const Home = () => {
  const flickityRef = useRef<Flickity | null>(null);

  const previousSlide = () => {
    if (flickityRef.current) {
      flickityRef.current.previous();
    }
  };

  const nextSlide = () => {
    if (flickityRef.current) {
      flickityRef.current.next();
    }
  };

  let marginTop = -300;
  let diffMarker = 100;
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const properties = useSelector(getProperties);
  const isLoading = useSelector(getIsLoading);
  const { setSignInLink } = useContext(SignInLinkContext);
  const navigate = useNavigate();

  useEffect(() => {
    // @ts-ignore
    dispatch(fetchAllProperties(10));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  // experiences should be fetched
  const slides = [
    {
      title: "Funtopia Water Park",
      desc: "Funtopia Water Park is the first of its kind in terms of its modern indoor and outdoor facilities for all age groups.",
      img: Funtopia,
      location: "Lekki, Lagos",
    },
    {
      title: "Lekki Conservation Centre",
      desc: "The establishment is a memorial built to honour Fela Kuti by his eldest son, Femi Kuti in 2000.",
      img: Lcc,
      location: "Lekki, Lagos",
    },
    {
      title: "The Good Beach",
      desc: "Experience London in Lagos. That’s what I hear many say about this new beach in Lagos, lol. It’s well-lit, fun-filled and has the...",
      img: Beach,
      location: "Lekki, Lagos",
    },
    {
      title: "New Afrika Shrine",
      desc: "The establishment is a memorial built to honour Fela Kuti by his eldest son, Femi Kuti in 2000.",
      img: Shrine,
      location: "Lekki, Lagos",
    },
    {
      title: "Lekki Conservation Centre",
      desc: "The establishment is a memorial built to honour Fela Kuti by his eldest son, Femi Kuti in 2000.",
      img: Lcc,
      location: "Lekki, Lagos",
    },
    {
      title: "The Good Beach",
      desc: "Experience London in Lagos. That’s what I hear many say about this new beach in Lagos, lol. It’s well-lit, fun-filled and has the...",
      img: Beach,
      location: "Lekki, Lagos",
    },
    {
      title: "New Afrika Shrine",
      desc: "The establishment is a memorial built to honour Fela Kuti by his eldest son, Femi Kuti in 2000.",
      img: Shrine,
      location: "Lekki, Lagos",
    },
  ];

  return (
    <div className="relative bg-eadWhite dark:bg-gray1000">
      <div className="absolute inset-0 top-0 mx-auto">
        <Navbar />
      </div>
      <div className="2xl:h-[50rem] md:h-[50rem] lg:h-[50rem] bg-eadWhite pt-32 dark:bg-gray1000 bg-no-repeat object-cover containerAlt bg-image-one dark:bg-image-two relative pb-18 lg:pb-4 z-100">
        {/* welcome search */}
        <div className="flex flex-col items-center justify-center gap-y-4 lg:gap-y-8 px-3 max-w-screen-2xl mx-auto 2xl:h-fit md:h-1/2 pt-8">
          <div className="flex flex-col gap-3">
            <span className="font-bold px-2 text-3xl xl:text-4xl lg:text-4xl text-gray1000 dark:text-gray300 font-display lg:text-center xl:text-center">
              There’s a home you’ll love
            </span>
            <span className="font-normal px-2 text-sm text-gray900 font-display lg:text-center xl:text-center dark:text-gray300">
              Discover your perfect space or list yours effortlessly. Your home,
              your way.
            </span>
          </div>
          <div className="rounded-full border bg-gray200 dark:bg-gray600 border-gray400 dark:border-gray700 py-1 md:pl-4 pl-2 pr-1.5 md:pr-2 flex items-center justify-between w-full lg:w-3/5 xl:w-3/5">
            <div
              onClick={() => setOpen(true)}
              className="cursor-pointer flex w-full items-center gap-2 bg-gray200 dark:bg-gray600"
            >
              <SearchIcon className="hidden lg:block xl:block w-4" />
              <div className=" flex flex-col w-2/3 ml-2">
                <span className="text-primary500 text-xs font-display -mb-0.5">
                  Enter location
                </span>
                <span className="border-transparent bg-transparent focus:border-transparent text-xs text-gray600 dark:text-gray300">
                  eg Banana island
                </span>
              </div>
            </div>
            <div className="flex items-center gap-2">
              <Link
                to={paths.SEARCH_LIST}
                className="px-4 py-2 shadow-lg rounded-full text-eadWhite bg-primary500 font-display text-xs font-semibold"
              >
                Search
              </Link>
              {/* <Settings /> */}
            </div>
          </div>
        </div>
      </div>

      {/* apartments */}
      {properties.length > 0 && (
        <div className="dark:bg-gray1000">
          <div className="flex gap-6 items-center justify-center flex-wrap max-w-screen-2xl mx-auto md:-mt-72 py-2 lg:py-8 dark:bg-gray1000">
            {(properties || []).map((item, index) => {
              diffMarker = marginTop === 0 ? -100 : diffMarker;
              if (marginTop + diffMarker === -300) {
                marginTop = -200;
                diffMarker = 100;
              } else {
                marginTop = marginTop + diffMarker;
              }

              return (
                <ShortletsCard
                  key={index}
                  img={item?.property_media[0].url}
                  price={item?.price}
                  duration={"per night"}
                  title={item?.name}
                  desc={item?.description}
                  location={item?.address}
                  marginTop={marginTop}
                  to={`${paths.VIEW_PROPERTY}/${item?.slug}`}
                  item={item}
                />
              );
            })}
          </div>
        </div>
      )}

      {/* vetting */}
      <div className="bg-gray300 dark:bg-eadBlack containerAlt">
        <div className="relative max-w-screen-2xl">
          <div className="md:flex items-center justify-center">
            <div className="w-full h-full cursor-pointer">
              <YoutubeEmbed />
            </div>
            <div className="p-4 lg:px-16 w-full">
              <div className="flex flex-col gap-3 my-10">
                <p className="font-display text-xl lg:text-2xl font-bold text-gray1000 dark:text-gray-300">
                  The most looked after properties
                </p>
                <p className="w-2/3 font-display text-sm text-gray-800 dark:text-gray-300">
                  Our experts have meticulously vetted every available rental,
                  filtering out the thousands that don’t meet our standards -
                  saving you from disappointment.
                </p>
                <button
                  onClick={() => {
                    navigate("/search");
                  }}
                  className="text-sm font-display rounded-full h-9 flex items-center justify-center px-3 bg-primary500 text-eadWhite font-bold w-fit"
                >
                  Find a property
                </button>
              </div>
              <div className="flex flex-col gap-3 my-10">
                <p className="font-display text-xl lg:text-2xl font-bold text-gray1000 dark:text-gray-300">
                  Earn from your property
                </p>
                <p className="w-2/3 font-display text-sm text-gray800 dark:text-gray-300">
                  List your space on EAD Shortlets and start earning today! Turn
                  your extra space or property into profit by hosting travellers
                  from around the world!
                </p>
                <Link
                  onClick={() => setSignInLink(paths.HOST_TYPE)}
                  to={paths.HOST_TYPE}
                  className="text-sm font-display rounded-full h-9 flex items-center justify-center px-3 bg-primary500 text-eadWhite font-bold w-fit"
                >
                  Try hosting
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* places */}
      <div className="py-12 bg-eadWhite dark:bg-gray1000 containerAlt">
        <div className=" relative mx-auto pb-24 px-4 lg:px-0">
          <div className="lg:flex xl:flex">
            <div className="lg:w-1/3 lg:pl-10 pl-4">
              <p className="font-display text-xl lg:text-2xl font-bold text-gray1000 mb-6 dark:text-gray-300">
                Top places to visit in Lagos
              </p>
              <p className="font-display text-base text-gray800 dark:text-gray-300">
                While you enjoy peace of mind in our short-let apartments, some
                of this places might just make your stay memorable.
              </p>
              {/* <button className="bg-primary500 font-display text-sm font-bold text-eadWhite text-center h-9 flex items-center justify-center px-3 rounded-full my-6">
                See curated experiences
              </button> */}
            </div>
            <div className="md:w-2/3 w-full relative">
              <Flickity
                flickityRef={(ref) => (flickityRef.current = ref)}
                className="carousel"
                elementType="div"
                options={{
                  // freeScroll: true,
                  contain: true,
                  draggable: true,
                  groupCells: true,
                  prevNextButtons: false,
                  pageDots: false,
                }}
                disableImagesLoaded={false}
                reloadOnUpdate
              >
                {slides.map((slide, index) => (
                  <div key={index} className="carousel-cell mr-4">
                    <PlacesCard
                      title={slide.title}
                      desc={slide.desc}
                      img={slide.img}
                      location={slide.location}
                    />
                  </div>
                ))}
              </Flickity>
              {/* Custom Arrow Buttons */}
              <button
                className="absolute left-0 -bottom-16 transform -translate-y-1/2 text-3xl text-white"
                onClick={previousSlide}
              >
                <div className="rounded-full flex items-center justify-center text-gray700 border border-gray300 p-2 w-10 h-10">
                  <IoIosArrowRoundBack className="w-full h-full object-cover" />
                </div>
              </button>
              <button
                className="absolute left-14 -bottom-16 transform -translate-y-1/2 text-3xl text-white"
                onClick={nextSlide}
              >
                <div className="rounded-full flex items-center justify-center text-gray700 border border-gray300 p-2 w-10 h-10">
                  <IoIosArrowRoundForward className="w-full h-full object-cover" />
                </div>{" "}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* customers */}
      <div className="bg-gray300 dark:bg-eadBlack">
        <div className="relative containerAlt mx-auto py-16 px-4">
          <p className="text-center mb-4 font-display dark:text-gray300">
            AS FEATURE IN
          </p>
          <div className="flex items-center justify-center gap-10 lg:gap-20 flex-wrap">
            <img
              src={Thisday}
              className="object-contain lg:h-10 h-8 w-auto"
              alt=""
            />
            <img
              src={Hotels}
              className="object-contain lg:h-10 h-8 w-auto"
              alt=""
            />
            <img
              src={Eaddark}
              className="object-contain lg:h-10 h-8 w-auto"
              alt=""
            />
            <img
              src={Tripads}
              className="object-contain lg:h-10 h-8 w-auto"
              alt=""
            />
            <img
              src={Bbc}
              className="object-contain lg:h-10 h-8 w-auto"
              alt=""
            />
          </div>
        </div>
      </div>

      {/* book for someoneelse */}
      <div className="flex flex-col items-center justify-center lg:pt-16 p-4 dark:bg-gray1000 containerAlt w-full">
        <div className="lg:w-4/5 lg:h-80 h-[28rem] rounded-2xl overflow-hidden flex flex-col relative bg-[#232323]">
          <img src={Book4} alt="" className="w-full h-auto flex md:hidden" />
          <div className="w-full font-display text-gray100 h-full absolute bg-gradient-to-t via-[#232323] md:bg-gradient-to-r from-[#232323] md:from-25% to-transparent md:from-[#232323] md:via-transparent justify-end md:justify-center gap-3 md:gap-2 p-5 md:p-10 flex flex-col">
            <div className="relative flex items-center justify-center w-fit mx-4 md:mb-4">
              <div className="bg-primary400  rounded-full animate-ping w-16 h-7"></div>
              <div className="text-xs absolute top-0 font-display rounded-full h-7 flex items-center justify-center w-24 bg-primary500 text-white">
                Coming soon
              </div>
            </div>
            <span className="font-bold text-4xl md:text-2xl">
              Book for someone else
            </span>
            <span className="md:w-1/3 md:text-sm">
              Make them feel home away from home and gift them the most sort
              after properties in Lagos, Abuja, London, Dubai.
            </span>{" "}
            {/* <button className="text-sm font-display rounded-full h-9 mt-2 flex items-center justify-center px-4 bg-gray400 text-gray1000 font-bold w-fit">
              Learn more
            </button> */}
          </div>
          <img
            src={Book4}
            alt=""
            className="w-full h-full object-cover ml-28 md:flex hidden"
          />
        </div>
      </div>

      {/* make bookings */}
      <MakeBookingSection />

      {/* footer */}
      <Footer />
      {/* <SearchModal open={open} setIsOpen={() => setOpen(false)} /> */}

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        className="w-full lg:w-1/2 mx-4"
      >
        <SearchModal open={open} setIsOpen={() => setOpen(false)} />
      </Modal>
    </div>
  );
};

export default Home;
