import React from "react";
import ArrowTopRight from "../../../../components/icons/arrow-top-right";
import { SingleBookingInfoType } from "../../../../redux/types";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Location from "../../../../components/icons/location";
import { BiCalendar } from "react-icons/bi";
import { MdClose } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa6";
import { useBookingInfo } from "../../../../hooks/use-book-summary";

interface DataCardProps {
  data: SingleBookingInfoType;
  filter: string;
}

const SVG1 = (
  <svg
    className="md:w-5 md:h-5 w-4 h-4"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="dark:stroke-primary100"
      d="M8 11.667C9.24264 11.667 10.25 10.6597 10.25 9.41704C10.25 8.1744 9.24264 7.16704 8 7.16704C6.75736 7.16704 5.75 8.1744 5.75 9.41704C5.75 10.6597 6.75736 11.667 8 11.667Z"
      stroke="#FF5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      className="dark:stroke-primary100"
      d="M1.25 7.59084C1.25 7.16008 1.25 6.94469 1.30552 6.74634C1.3547 6.57064 1.43552 6.40539 1.54402 6.2587C1.6665 6.09309 1.83652 5.96086 2.17654 5.6964L7.26327 1.74005C7.52677 1.53511 7.65851 1.43264 7.80399 1.39325C7.93235 1.3585 8.06765 1.3585 8.19601 1.39325C8.34149 1.43264 8.47324 1.53511 8.73673 1.74005L13.8235 5.6964C14.1635 5.96086 14.3335 6.09309 14.456 6.2587C14.5645 6.40539 14.6453 6.57064 14.6945 6.74634C14.75 6.94469 14.75 7.16008 14.75 7.59084V13.017C14.75 13.8571 14.75 14.2772 14.5865 14.598C14.4427 14.8803 14.2132 15.1097 13.931 15.2536C13.6101 15.417 13.1901 15.417 12.35 15.417H3.65C2.80992 15.417 2.38988 15.417 2.06901 15.2536C1.78677 15.1097 1.5573 14.8803 1.41349 14.598C1.25 14.2772 1.25 13.8571 1.25 13.017V7.59084Z"
      stroke="#FF5C5C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const SVG2 = (
  <svg
    className="w-4 h-4"
    width="18"
    height="14"
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className="dark:stroke-green-100"
      d="M1.5 3.42578L7.62369 7.71236C8.11957 8.05948 8.36751 8.23304 8.6372 8.30026C8.87542 8.35964 9.12458 8.35964 9.3628 8.30026C9.63249 8.23304 9.88043 8.05948 10.3763 7.71236L16.5 3.42578M5.1 13.1758H12.9C14.1601 13.1758 14.7902 13.1758 15.2715 12.9305C15.6948 12.7148 16.039 12.3706 16.2548 11.9473C16.5 11.466 16.5 10.8359 16.5 9.57578V4.77578C16.5 3.51566 16.5 2.8856 16.2548 2.4043C16.039 1.98094 15.6948 1.63673 15.2715 1.42102C14.7902 1.17578 14.1601 1.17578 12.9 1.17578H5.1C3.83988 1.17578 3.20982 1.17578 2.72852 1.42102C2.30516 1.63673 1.96095 1.98094 1.74524 2.4043C1.5 2.8856 1.5 3.51566 1.5 4.77578V9.57578C1.5 10.8359 1.5 11.466 1.74524 11.9473C1.96095 12.3706 2.30516 12.7148 2.72852 12.9305C3.20982 13.1758 3.83988 13.1758 5.1 13.1758Z"
      stroke="#219653"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const getStatusMessage2 = (status: string) => {
  switch (status) {
    case "awaiting_approval":
      return {
        title: "Booking request",
        svg: SVG1,
        color:
          "bg-primary100 dark:bg-[#660808] dark:text-primary100 text-primary400",
      };
    case "upcoming":
      return {
        title: "Booking request",
        svg: SVG1,
        color:
          "bg-primary100 dark:bg-[#660808] dark:text-primary100 text-primary400",
      };
    case "completed":
      return {
        title: "Completed",
        svg: SVG2,
        color:
          "bg-gray300 dark:bg-[#00411C] dark:text-green-100 text-green-600",
      };
    case "reserved":
      return {
        title: "Completed",
        svg: SVG2,
        color:
          "bg-gray300 dark:bg-[#00411C] dark:text-green-100 text-green-600",
      };
    case "cancelled":
      return {
        title: "Cancelled",
        svg: SVG1,
        color:
          "bg-primary100 dark:bg-[#660808] dark:text-primary100 text-primary400",
      };
    default:
      return {
        title: "Booking request",
        svg: SVG1,
        color:
          "bg-primary100 dark:bg-[#660808] dark:text-primary100 text-primary400",
      };
  }
};

const DataCard: React.FC<DataCardProps> = ({ data, filter }) => {
  const { approveBooking, declineBooking } = useBookingInfo({
    bookingId: Number(data.id),
  });
  const navigate = useNavigate();
  return (
    <div className="border border-gray400 dark:border-gray800 lg:flex 2xl:flex gap-3 rounded-xl min-h-24 p-3 mb-4">
      <img
        src={
          data.property?.property_media
            ? data.property?.property_media[0]?.url
            : ""
        }
        alt={data.property.name}
        className="rounded-2xl w-full md:w-72 pb-2 bg-gray400"
      />
      <div className="flex flex-col gap-2 w-full">
        {filter === "completed" && (
          <div
            className={`${
              getStatusMessage2(data.status).color
            } w-fit gap-2 flex justify-center items-center p-1 rounded-full`}
          >
            {getStatusMessage2(data.status).svg}
            <span className="font-medium text-xs">
              {getStatusMessage2(data.status).title}
            </span>
          </div>
        )}
        {filter === "awaiting_approval" ||
          (filter === "upcoming" && (
            <div
              className={`bg-gray300 w-fit gap-2 flex justify-center items-center p-1 rounded-full`}
            >
              <span className="font-medium text-xs">
                {moment(data.check_in_date).fromNow(true)} remaining
              </span>
            </div>
          ))}
        <p className="text-sm font-semibold">{data.property.name}</p>
        <p className="text-xs flex">
          <Location className="w-3" /> {data.property.address}
        </p>
        <p className="text-xs font-semibold">
          Booking by{" "}
          <span className="text-primary500">{data.guest_info.first_name}</span>
        </p>
        <p className="text-xs">{data.number_of_adults} guests</p>
        <div>
          <p className="text-xs flex items-center gap-2">
            Check in <BiCalendar className="w-4" />
            {moment(data.check_in_date).format("DD/MM/YYYY")}
          </p>
          <p className="text-xs flex items-center gap-2">
            Check out <BiCalendar className="w-4" />
            {moment(data.check_out_date).format("DD/MM/YYYY")}
          </p>
        </div>
        <div className="flex items-center justify-between w-full">
          <button
            onClick={() => navigate(`/booking-info/${data.id}`)}
            className="bg-gray300 rounded-full text-xs flex w-fit items-center font-semibold gap-2 p-2"
          >
            View details <ArrowTopRight color="#525252" />
          </button>
          <div className="flex items-center gap-2">
            {filter === "awaiting-approval" && (
              <button
                onClick={declineBooking}
                className="bg-gray300 rounded-full text-xs flex w-fit items-center font-semibold gap-2 p-2"
              >
                <MdClose size={20} /> Decline
              </button>
            )}
            {filter === "awaiting-approval" && (
              <button
                onClick={approveBooking}
                className="bg-primary400 text-gray100 rounded-full text-xs flex w-fit items-center font-semibold gap-2 p-2"
              >
                Approve
              </button>
            )}
            {filter !== "awaiting-approval" && filter !== "completed" && (
              <button className="bg-primary400 text-gray100 rounded-full text-xs flex w-fit items-center font-semibold gap-2 p-2">
                <FaWhatsapp size={15} /> Send message
              </button>
            )}
            {filter === "completed" && (
              <button className="bg-gray400 text-gray1000 rounded-full text-xs flex w-fit items-center font-semibold gap-2 p-2">
                Add a review
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataCard;
