import { urls } from "../../network";
import apiClient from "../../network/axios-client";
import { showToast } from "../../utils/helper-functions";
import { setLoading } from "../reducers/loader-slice";
import {
  setDashboardStats,
  setFavourites,
  setGuestBookings,
  setHostBooking,
  setProperties,
  setProperty,
  setSimilarProperties,
  setVerificationChecklist,
} from "../reducers/property-slice";
import { Place, Places, PropertyFormType } from "../types";
const client = apiClient;

// thunks
export const fetchAllProperties = (limit: number) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const res = await client.get(urls.ViewAllProperties(limit));
    dispatch(setLoading(false));
    if (res.data.hasError) {
    } else {
      dispatch(setProperties(res.data.data.homePageListings));
      // dispatch(setPagination(res.data.data.pagination));
    }
  } catch (error) {
    dispatch(setLoading(false));
    console.log(error);
    // showToast('An error occurred!', 'error');
  }
};

export const fetchSingleProperty = (id: string) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const res = await client.get(urls.ViewSingleProperties(id));
    if (res.data.hasError) {
      dispatch(setLoading(false));
      showToast(res.data.message, "error");
    } else {
      dispatch(setProperty(res.data.data.property));
      dispatch(setLoading(false));
    }
  } catch (error) {
    console.log(error);
    dispatch(setLoading(false));
    showToast("An error occurred!", "error");
  }
};

export const uploadImagesSingle =
  (credentials: any) => async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const res = await client.post(urls.MediaUploadOne, credentials);
      if (res.data.hasError) {
        showToast(res.data.message, "error");
        dispatch(setLoading(false));
        return false;
      } else {
        dispatch(setLoading(false));
        return res.data.data.media_url;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // showToast('An error occurred!', 'error');
      console.log(error);
    }
  };

export const uploadImagesMultiple =
  (credentials: any) => async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const res = await client.post(urls.MediaUploadMultiple, credentials);
      if (res.data.hasError) {
        showToast(res.data.message, "error");
        dispatch(setLoading(false));
        return false;
      } else {
        dispatch(setLoading(false));
        return res.data.data.media_urls;
      }
    } catch (error) {
      dispatch(setLoading(false));
      // showToast('An error occurred!', 'error');
      console.log(error);
    }
  };

export const uploadDocsSingle = (credentials: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const res = await client.post(urls.DocumentUploadOneClean, credentials);
    if (res.data.hasError) {
      showToast(res.data.message, "error");
      dispatch(setLoading(false));
      return false;
    } else {
      dispatch(setLoading(false));
      return res.data.data.media_url;
    }
  } catch (error) {
    dispatch(setLoading(false));
    // showToast('An error occurred!', 'error');
    console.log(error);
  }
};

export const allCategories = () => async (dispatch: any) => {
  try {
    const res = await client.get(urls.AllCategories);
    if (res.data.hasError) {
    } else {
      localStorage.setItem("allcategories", JSON.stringify(res.data.data));
    }
  } catch (error) {
    console.log(error);
  }
};

export const createProperty =
  (credentials: PropertyFormType) => async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const res = await client.post(urls.AddProperty, credentials);
      if (res.data.hasError) {
        dispatch(setLoading(false));
        showToast(res.data.errors.message, "error");
        return;
      } else {
        showToast(res.data.title, "success");
        dispatch(setLoading(false));
        return res.data.data.property;
      }
    } catch (error) {
      console.log(error);
      showToast("An error occurred!", "error");
      dispatch(setLoading(false));
      return false;
    }
  };

export const updateProperty =
  (credentials: PropertyFormType) => async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const res = await client.patch(urls.EditProperty, credentials);
      if (res.data.hasError) {
        dispatch(setLoading(false));
        showToast(res.data.errors.message, "error");
        return;
      } else {
        showToast(res.data.title, "success");
        // dispatch(fetchUser());
        dispatch(setLoading(false));
        return true;
      }
    } catch (error) {
      console.log(error);
      showToast("An error occurred!", "error");
      dispatch(setLoading(false));
      return false;
    }
  };

export const updatePropertyPublished =
  (credentials: { id: string; is_live: boolean }) => async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const res = await client.patch(
        urls.UpdatePublished(credentials.id),
        credentials
      );
      if (res.data.hasError) {
        dispatch(setLoading(false));
        showToast(res.data.errors.message, "error");
        return;
      } else {
        showToast(res.data.title, "success");
        dispatch(setLoading(false));
        return true;
      }
    } catch (error) {
      console.log(error);
      showToast("An error occurred!", "error");
      dispatch(setLoading(false));
      return false;
    }
  };

export const updatePropertyBlockedDates =
  (credentials: {
    property_id: number;
    blocked_out_dates: {
      date: string;
      should_store: boolean;
    }[];
  }) =>
  async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const res = await client.patch(urls.UpdateBlockedDates, credentials);
      if (res.data.hasError) {
        dispatch(setLoading(false));
        showToast(res.data.errors.message, "error");
        return;
      } else {
        showToast(res.data.title, "success");
        dispatch(setLoading(false));
        return true;
      }
    } catch (error) {
      console.log(error);
      showToast("An error occurred!", "error");
      dispatch(setLoading(false));
      return false;
    }
  };

export const viewChecklist = () => async (dispatch: any) => {
  try {
    const res = await client.get(urls.ViewVerificationChecklist);
    if (res.data.hasError) {
    } else {
      dispatch(setVerificationChecklist(res.data.data));
    }
  } catch (error) {
    console.log(error);
  }
};

export const deleteProperty =
  (id: string | number) => async (dispatch: any) => {
    try {
      const res = await client.patch(urls.DeleteProperty(id), {});
      if (res.data.hasError) {
        return false;
      } else {
        showToast(res.data.title, "success");

        return true;
      }
    } catch (error) {
      console.log(error);
    }
  };

export const updatePropertyDocument =
  (credentials: {
    property_id: string | number;
    document_type: string;
    document_image_url: string;
  }) =>
  async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      const res = await client.post(urls.UpdatePropertyRequestKyc, credentials);
      if (res.data.hasError) {
        dispatch(setLoading(false));
        showToast(res.data.errors.message, "error");
        return;
      } else {
        showToast(res.data.title, "success");
        dispatch(setLoading(false));
        return true;
      }
    } catch (error) {
      console.log(error);
      showToast("An error occurred!", "error");
      dispatch(setLoading(false));
      return false;
    }
  };

export const fetchSimilarProperty = (id?: any) => async (dispatch: any) => {
  try {
    // dispatch(setLoading(true));
    const res = await client.get(urls.ViewSimilarProperties(id));
    if (res.data.hasError) {
      // dispatch(setLoading(false));
      // showToast(res.data.message, "error");
    } else {
      dispatch(setSimilarProperties(res.data.data.similarProperties));
      // dispatch(setLoading(false));
    }
  } catch (error) {
    console.log(error);
    dispatch(setLoading(false));
    showToast("An error occurred!", "error");
  }
};

export const makeSpecialReservations =
  (credentials: {
    first_name: string;
    last_name: string;
    email: string;
    phone_number: string;
    message: string;
    to_do_type: string;
  }) =>
  async (dispatch: any) => {
    try {
      // dispatch(setLoading(true));
      const res = await client.post(urls.SpecialReservation, credentials);
      if (res.data.hasError) {
        // dispatch(setLoading(false));
        showToast(res.data.errors.message, "error");
        return;
      } else {
        showToast(res.data.title, "success");
        // dispatch(setLoading(false));
        return true;
      }
    } catch (error) {
      console.log(error);
      showToast("An error occurred!", "error");
      // dispatch(setLoading(false));
      return false;
    }
  };

export const dashboardStatsApi = () => async (dispatch: any) => {
  try {
    const res = await client.get(urls.DashboardStats);
    if (res.data.hasError) {
    } else {
      dispatch(setDashboardStats(res.data.data.dashboard_stats));
    }
  } catch (error) {
    console.log(error);
  }
};

export const autocompleteApi = (input: string) => async (dispatch: any) => {
  try {
    const res = await client.get(urls.AutocompleteplacesUrl(input));
    if (res.data.hasError) {
      return [];
    } else {
      return res.data.data.predictions as Places;
    }
  } catch (error) {
    console.log(error);
  }
};

export const googlePlaceInfoApi = (id: string) => async (dispatch: any) => {
  try {
    const res = await client.get(urls.GooglePlaceInfoUrl(id));
    if (res.data.hasError) {
      return {};
    } else {
      return res.data.data as Place;
    }
  } catch (error) {
    console.log(error);
  }
};

export const fetchGuestBookings = (filter?: string) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const res = await client.get(urls.ViewGuestBookings(filter));
    if (res.data.hasError) {
      dispatch(setLoading(false));
      showToast(res.data.message, "error");
    } else {
      dispatch(setGuestBookings(res.data.data));
      dispatch(setLoading(false));
    }
  } catch (error) {
    console.log(error);
    dispatch(setLoading(false));
    showToast("An error occurred!", "error");
  }
};

export const fetchFavoriteProperties = () => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const res = await client.get(urls.ViewFavourites);
    if (res.data.hasError) {
      dispatch(setLoading(false));
      showToast(res.data.message, "error");
    } else {
      dispatch(setFavourites(res.data.data?.favouriteProperties));
      dispatch(setLoading(false));
    }
  } catch (error) {
    console.log(error);
    dispatch(setLoading(false));
    showToast("An error occurred!", "error");
  }
};

export const removeFavouriteProperty = (id: any) => async (dispatch: any) => {
  try {
    const res = await client.delete(urls.RemoveFavourite(id));
    if (res.data.hasError) {
      return false;
    } else {
      // showToast(res.data.title, "success");
      return true;
    }
  } catch (error) {
    console.log(error);
  }
};

export const addFavouriteProperty =
  (body: { property_id: number }) => async (dispatch: any) => {
    try {
      const res = await client.post(urls.AddFavourite, body);
      if (res.data.hasError) {
        return false;
      } else {
        // showToast(res.data.title, "success");
        dispatch(fetchFavoriteProperties());
        return true;
      }
    } catch (error) {
      console.log(error);
    }
  };

export const fetchHostBookings = (filter?: string) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const res = await client.get(urls.ViewHostBookings(filter));
    if (res.data.hasError) {
      dispatch(setLoading(false));
      showToast(res.data.message, "error");
    } else {
      console.log("hostttt", res.data.data.bookings, res.data.data.bookings);
      dispatch(setHostBooking(res.data.data.bookings));
      dispatch(setLoading(false));
    }
  } catch (error) {
    console.log(error);
    dispatch(setLoading(false));
    showToast("An error occurred!", "error");
  }
};

export const validatePropertyBooking =
  (body: { booking_id: number; decision: string }) => async (dispatch: any) => {
    try {
      const res = await client.post(urls.ValidateBooking, body);
      if (res.data.hasError) {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      console.log(error);
    }
  };
